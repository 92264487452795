<template>
    <PageLayout>
        <template v-slot:side>
            <v-icon class="mb-5" color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true">mdi-filter-plus</v-icon>
            <v-list class="filter_left">
                <v-list-group :ripple="false" v-model="group1" :group="'group1'" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('system_filters') }}</v-list-item-title>
                    </template>

                    <v-list-item :ripple="false" v-for="(item, i) in system_filters" :key="i" link>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                        <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-list class="filter_left">
                <v-list-group :ripple="false" v-model="group2" :group="'group2'" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('additional') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" link>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                        <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </template>

        <template>
            <v-form @submit.prevent="getInvites('search')" class="mb-10">
                <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'invite.create'}" color="indigo" fab fixed top right class="v-btn--add-form-top white--text">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
                <TabNav />
                <v-row>
                    <v-col cols="12" sm="10">
                        <h1>{{ filter_show ? $t('search_invite') : $t('invite') }}</h1>
                    </v-col>
                    <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" v-show="!filter_show" class="text-right">
                        <Btn @click="dialogAdd=true" color="secondary" large>
                            {{ $t('create') }}
                        </Btn>
                    </v-col>
                </v-row>
                <v-row v-if="filter_show">
                    <v-col class="py-0" cols="12" sm="12">
                        <v-text-field
                            v-model="email_search"
                            type="email_search"
                            :label="$t('email')"
                            outlined
                            solo
                            flat
                            dense
                            :disabled="loading"
                            :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                            color="primary"
                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                            clearable>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="filter_show">
                    <v-col cols="12" sm="3" md="2">
                        <SelectInput
                            id="perPage"
                            :value="perPage"
                            :items="perPageItems"
                            :label="$t('items_per_page')"
                            @input="perPage = options.itemsPerPage = Number($event)"
                            hide-details
                            outlined
                            solo
                            flat
                            dense
                            :disabled="loading"
                            :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                            color="primary"
                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                        ></SelectInput>
                    </v-col>
                    <v-col cols="12" sm="9" md="10" class="d-flex justify-center justify-sm-end align-self-center">
                        <v-btn type="submit" :disabled="loading" :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">{{ $t('search') }}</v-btn>
                    </v-col>
                </v-row>
            </v-form>

            <ResizableTable
                class="main-data-table elevation-0 uveds-table"
                :rows="invites"
                :columns="filteredHeaders.map((item) => {
                    return {
                        ...item,
                        prop: item.value,
                        label: item.text,
                        width: 'auto'
                    }
                })"
            >
                <template v-slot:email="{ item }">
                    <div :class="item.deleted ? 'red--text' : ''">
                            <span class="cursor-pointer font_weight_600" @click="detailsInvite(item)">
                                {{ item.email }}
                                {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                            </span>
                        <v-icon v-if="!item.deleted" @click="deleteInvite(item)" small>mdi-delete-outline</v-icon>
                    </div>
                </template>
                <template v-slot:accepted="{ item }">
                    <div>{{ item.accepted ? $t('yes') : $t('no') }}</div>
                </template>
                <template v-slot:sent_date="{ item }">
                    <div>{{ $moment(item.sent_date).format('DD.MM.YYYY HH:mm:ss') }}</div>
                </template>
                <template v-slot:action="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" class="mr-2" @click="detailsInvite(item)" :title="$t('details')">
                                mdi-card-text-outline
                            </v-icon>
                        </template>
                        <span>{{ $t('invite_details') }}</span>
                    </v-tooltip>


                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" :disabled="item.deleted" @click="deleteInvite(item)" icon>
                                <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                    </v-tooltip>
                </template>
            </ResizableTable>

            <v-pagination v-if="invites.length > 0" v-model="page" :total-visible="totalVisiblePag" class="pagination_table" :length="pageCount" :disabled="loading"></v-pagination>

            <v-dialog v-if="dialogAdd" v-model="dialogAdd"
                      scrollable
                      persistent
                      max-width="500px"
                      transition="dialog-bottom-transition"
            >

                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">

                    <v-card>
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{ $t('invite_creation') }}</span>
                            </div>
                            <div>
                                <v-icon @click="closeDialogAdd" :title="$t('close')">mdi-close</v-icon>
                            </div>
                        </v-toolbar>
                        <v-card-text class="pa-2 pb-0">
                            <ValidationProvider ref="email" rules="required|email|min:5|max:50"
                                                v-slot="{ errors, valid }">
                                <v-text-field v-model="email" type="email"
                                              :error-messages="errors" :error="!valid"
                                              :disabled="loading || !can(['administrator', 'manager'])"
                                              :readonly="!can(['administrator', 'manager'])"
                                              :label="$t('email')"
                                              clearable
                                              outlined
                                              solo
                                              flat
                                              dense
                                              hide-details
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 input_text">
                                </v-text-field>
                            </ValidationProvider>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="sentInvite()"
                                   :disabled="invalid || loading">
                                {{ $t('sent') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-dialog>

            <v-dialog v-if="dialogDetails" v-model="dialogDetails"


                      max-width="600"
                      transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('invite_details') }}</span>
                        </div>
                        <div>
                            <v-icon @click="cancelDetails" :title="$t('close')">mdi-close</v-icon>
                        </div>
                    </v-toolbar>

                    <v-card-text class="pa-5 pb-0">
                        <v-row class="pb-5">
                            <v-col cols="12"><b> {{ $t('accepted') }}: </b>{{ detail.accept_date ? $t('yes') : $t('no') }}</v-col>
                            <v-col cols="12"><b> {{ $t('email') }}: </b>{{ detail.email ? detail.email : $t('no_data') }}</v-col>
                            <v-col cols="12"><b> {{ $t('sent_date') }}: </b>{{ detail.sent_date ? $moment(detail.sent_date).format('DD.MM.YYYY HH:mm:ss') : $t('no_data') }}</v-col>
                            <v-col cols="12" v-if="detail.client && detail.client.name ? true : false"><b> {{ $t('accept_date') }}: </b>{{ detail.accept_date ? $moment(detail.accept_date).format('DD.MM.YYYY HH:mm:ss') : $t('no_data') }}</v-col>
                            <v-col cols="12" v-if="detail.client && detail.client.name ? true : false">
                                <b> {{ $t('client') }}: </b>
                                <router-link class="text-primary" :to="{ name: 'client.profile', params: {'id' : detail.client.id}}" target="_blank">
                                    {{ detail.client.name }}
                                </router-link>
                            </v-col>
                            <v-col cols="12"><b> {{ $t('sender') }}: </b>{{ detail.admin && detail.admin.name ? detail.admin.name : $t('no_data') }}</v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </template>
    </PageLayout>
</template>

<script>
import {mapGetters} from "vuex"
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import PageLayout from "@/components/Leentech/PageLayout.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import TabNav from "@/components/Leentech/TabsNav.vue";
import Btn from "@/components/Form/Btn.vue";
import FilterView from "@/plugins/mixins/FilterView";
import NavMixin from "@/plugins/mixins/Nav";

export default {
    name: "Invites",
    components: {
        Btn,
        SelectInput,
        PageLayout,
        ValidationProvider,
        ValidationObserver,
        TabNav
    },
    mixins: [
        FilterView,
        NavMixin
    ],
    data() {
        return {
            fab: false,
            email: null,
            email_search: null,
            invites: [],
            inviteItems: [],
            sortBy: "id",
            sortDir: false,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 7,
            totalInvites: 0,
            options: {
                ...FilterView.data().options,
                itemsPerPage: 10,
                sortBy: [
                    "id"
                ],
            },
            detail: {},
            loading: false,
            loadingDetail: false,
            headers: [
                {
                    active: true,
                    text: this.$t('email'),
                    align: "left",
                    value: "email"
                },
                {
                    active: true,
                    text: this.$t('sent_date'),
                    align: "left",
                    value: "sent_date"
                },
                {
                    active: true,
                    text: this.$t('accepted'),
                    align: "left",
                    value: "accepted",
                },
            ],
            dialogAdd: false,
            dialogDetails: false,
            system_filters: [
                {'title': 'Все контакты', 'count': '10'},
                {'title': 'Мои клиенты', 'count': '0'},
                {'title': 'Избранное', 'count': '7'},
            ],
            Additional: [
                {'title': 'База Казахстан', 'count': '10'},
                {'title': 'База Россия', 'count': '10'},
                {'title': 'База Узбекистан', 'count': '10'},
            ],
            group1: true,
            group2: true,
            filter_show: false,

        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },
    },

    methods: {

        init() {
            this.processQuery();
            this.getItems();
        },

        rowClass() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table3' : 'rowMobiClass';
        },

        closeDialogAdd() {
            this.email = null
            this.dialogAdd = false;
        },
        cancelDetails() {
            this.dialogDetails = false;
        },
        async detailsInvite(item) {
            this.loadingDetail = true;
            this.dialogDetails = true;

            await this.$http
                .get("admin/invite/" + item.id)
                .then(res => {
                    this.detail = res.body.data
                    this.dialogDetails = true;
                })
                .catch(err => {
                    this.orderItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_orders'))
                })
                .finally(end => {
                    this.loadingDetail = false
                })

        },


        editInvite(item) {
            this.$router.push({
                name: 'invite.profile',
                params: {
                    id: item.id
                }
            })
        },
        async deleteInvite(item) {
            if (confirm(this.$t('delete_invite'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/invite/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('invite_has_been_deleted'))
                        this.getInvites()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('invite_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async getInvites(type) {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            }
            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }
            if (this.email_search) {
                params.email = this.email_search;
            }
            await this.$http
                .get("admin/invite", {
                    params: params,
                })
                .then(res => {
                    this.invites = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalInvites = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.invites = []
                    this.totalInvites = 0
                    this.$toastr.error(this.$t('failed_to_get_list_invites'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async sentInvite() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            if (this.email) {
                formData.append('email', this.email)
            }

            // Add
            await this.$http
                .post('admin/invite', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('invite_has_been_added'))
                    this.dialogAdd = false
                    this.email = null
                    this.getInvites();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('invite_has_not_been_added'))
                    this.dialogFileError = true
                    this.textFileError = err.body.message;

                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }

                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
        async getItems() {
            await this.getInvites();
        },
    }
}
</script>
